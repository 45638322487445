export function parseFilter(
  filter: Record<string, string[]>,
  current?: Record<string, string>,
  sorter: any = {},
) {
  const query: Record<string, string> = {};
  const currKeys = Object.keys(filter);
  for (let key in current) {
    key = key.replace(/\[.*?\]/g, '');
    if (!currKeys.includes(key)) {
      query[key] = current[key];
    }
  }

  for (const key in filter) {
    if (filter[key] !== null) {
      if (filter[key].length === 1) {
        query[key] = filter[key][0];
      } else if (filter[key].includes('$regex')) {
        query[`${key}[$regex]`] = filter[key][0];
        query[`${key}[$options]`] = 'i';
      } else {
        for (const value of filter[key]) {
          query[`${key}[$in][]`] = value;
        }
      }
    }
  }

  // Remove sort by default
  delete query['$sort'];
  if (sorter?.order) {
    query[`$sort[${sorter.columnKey}]`] = sorter.order === 'ascend' ? '1' : '-1';
  }

  return query;
}
